<template>
  <div>
    <!-- <a-row>
      <a-col :lg="{ span: 14, offset: 5 }" :xs="{ span: 20, offset: 2 }">
        <div class="WrapLogin">
          <div class="LoginForm">
            <a-row>
              <a-col :lg="{ span: 12 }">
                <div class="SubLoginlogo">
                  <img :src="require('@/assets/img/logo-login.png')" />
                  <h2>Selamat Datang</h2>
                  <h3>Masuk Aplikasi</h3>
                </div>
              </a-col>
              <a-col :lg="{ span: 12 }">
                <div class="SubLoginForm">
                  <Auth />
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>
    </a-row> -->

    <div class="zoomlogin">
      <div class="WrapLogin">
        <div class="LoginForm">
          <div class="flexboxlogin">
            <div>
              <div class="SubLoginlogo">
                <img :src="require('@/assets/img/logo-login.png')" />
                <h2>Selamat Datang</h2>
                <h3>Masuk Aplikasi</h3>
              </div>
            </div>
            <div>
              <div class="SubLoginForm">
                <Auth />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Auth from "@/components/Auth/login.vue";

export default {
  beforeCreate: function () {
    document.body.className = "login";
  },
  name: "Login",
  components: {
    Auth,
  },
};
</script>

<style scoped>
.zoomlogin {
  width: 1021px;
  margin: 0 auto;
}

.flexboxlogin {
  display: flex;
}

.flexboxlogin > div {
  width: 31.90625rem;
}
</style>
