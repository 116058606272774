<template>
  <a-modal
    :title="title"
    :visible="visible"
    @cancel="handleCancel"
    :centered="true"
    :footer="null"
    width="600px"
  >
    <div v-if="otp" style="font-size: 16px; font-weight: 400">
      Masukkan OTP yang sudah di kirim ke Email Anda
    </div>
    <a-form :form="form" @submit="handleOk">
      <a-form-item label="Kode OTP" v-if="otp">
        <div slot="extra">
          <span v-if="countDown > 0"
            >OTP akan berakhir dalam {{ countDown }} detik</span
          ><span v-else>
            Resend OTP Click
            <a-button type="link" @click="ResendOtp">Here</a-button>
          </span>
        </div>
        <a-input
          autocomplete="off"
          v-decorator="[
            'otp',
            {
              rules: [
                {
                  required: true,
                  message: 'Field ini tidak boleh kosong',
                },
              ],
            },
          ]"
          placeholder="Masukan Kode"
        />
      </a-form-item>
      <a-form-item label="Email" v-else>
        <a-input
          autocomplete="off"
          type="email"
          v-decorator="[
            'email',
            {
              rules: [
                {
                  required: true,
                  message: 'Field ini tidak boleh kosong',
                },
              ],
            },
          ]"
          placeholder="Input email"
          :disabled="disabled"
        />
      </a-form-item>

      <a-form-item :wrapper-col="{ lg: 5, offset: 21 }">
        <a-button
          v-if="otp"
          type="primary"
          :loading="loading"
          html-type="submit"
          >Kirim</a-button
        >
        <a-button type="primary" html-type="submit" :loading="loading" v-else
          >Kirim</a-button
        >
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}
export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "ForgotPassword" });
  },
  data() {
    return {
      visible: false,
      countDown: 0,
      title: "Lupa Password",
      otp: false,
      hasErrors,
      loading: false,
      count_down_timer: null,
      email: "",
      disabled: false,
      emailprops: "",
    };
  },

  mounted() {
    this.$nextTick(() => {
      // To disabled submit button at the beginning.
      this.form.validateFields();
    });
  },

  watch: {
    countDown: {
      handler: function (action) {
        if (action == 0) {
          clearTimeout(this.count_down_timer);
        }
      },
      deep: true,
    },

    emailprops: {
      handler: function (action) {
        if (action != null) {
          console.log("wuasem", action);
          //this.form.setFieldsValue({ email: action });
        }
      },
      deep: true,
      // immediate: true,
    },
  },

  methods: {
    ResendOtp() {
      this.loading = true;
      this.form.resetFields("otp");
      this.$store
        .dispatch("GetOTP", {
          email: this.email,
        })
        .then((response) => {
          this.loading = false;
          this.$notification.success({
            message: response.data.message,
          });
          this.countDown = 120;
          this.countDownTimer();
        })
        .catch((error) => {
          this.loading = false;
          this.countDown = 0;
        });
    },
    countDownTimer() {
      if (this.countDown > 0) {
        this.count_down_timer = setTimeout(() => {
          this.countDown--;
          this.countDownTimer();
        }, 1000);
      }
    },
    otpError() {
      const { getFieldError, isFieldTouched } = this.form;

      return isFieldTouched("otp") && getFieldError("otp");
    },
    emailError() {
      const { getFieldError, isFieldTouched } = this.form;

      return isFieldTouched("email") && getFieldError("email");
    },

    handleOk(e) {
      e.preventDefault();

      if (this.otp) {
        e.preventDefault();
        this.form.validateFields((err, value) => {
          if (!err) {
            this.loading = true;
            this.$store
              .dispatch("SentOTP", {
                otp: value.otp,
              })
              .then((response) => {
                this.loading = false;
                if (response.data.message != "OTP tidak valid") {
                  this.otp = false;
                  this.visible = false;
                  clearTimeout(this.count_down_timer);
                  this.$emit("reset", response.data.data);
                } else {
                  this.$notification.success({
                    message: response.data.message,
                  });
                }
              })
              .catch((error) => {
                this.loading = false;
              });
          }
        });
      } else {
        this.form.validateFields((err, value) => {
          if (!err) {
            this.loading = true;
            this.email = value.email;
            this.$store
              .dispatch("GetOTP", {
                email: value.email,
              })
              .then((response) => {
                this.loading = false;
                this.$notification.success({
                  message: response.data.message,
                });
                this.otp = true;
                if (this.otp) {
                  this.title = "OTP Authentication";
                  this.countDown = 120;
                  this.countDownTimer();
                }
              })
              .catch((error) => {
                this.loading = false;
                this.countDown = 0;
              });
          }
        });
      }
    },

    handleCancel() {
      this.visible = false;
      this.otp = false;
      this.loading = false;
    },
    Show(item) {
      if (item != null) {
        // console.log("kan", item);

        this.emailprops = item;
        //this.form.setFieldsValue({ email: item });

        this.form.getFieldDecorator("email", {
          initialValue: item,
          preserve: true,
        });
      }
      this.visible = true;
    },
  },
};
</script>
