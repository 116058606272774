var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":"Please Change your Password","centered":true,"width":"600px","footer":null,"closable":false,"maskClosable":false,"keyboard":false},model:{value:(_vm.modals),callback:function ($$v) {_vm.modals=$$v},expression:"modals"}},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.submit}},[_c('a-form-item',{attrs:{"label":"Password"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                {
                  required: true,
                  message: 'Silahkan isi password baru!',
                },
                {
                  min: 6,
                  message: 'Karakter password harus lebih dari 6, ',
                },
                {
                  validator: _vm.RegexPassword,
                },
              ],
            },
          ]),expression:"[\n            'password',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Silahkan isi password baru!',\n                },\n                {\n                  min: 6,\n                  message: 'Karakter password harus lebih dari 6, ',\n                },\n                {\n                  validator: RegexPassword,\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Password Baru"},on:{"blur":_vm.handleConfirmBlur}})],1),_c('a-form-item',{attrs:{"label":"Konfirmasi Password"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'confirm_password',
            {
              rules: [
                {
                  required: true,
                  message: 'Silahkan konfirmasi password baru',
                },
                { validator: _vm.compareToFirstPassword },
              ],
            },
          ]),expression:"[\n            'confirm_password',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Silahkan konfirmasi password baru',\n                },\n                { validator: compareToFirstPassword },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Konfrimasi Password Baru"},on:{"blur":_vm.handleConfirmBlur}})],1),_c('a-form-item',{attrs:{"wrapper-col":{ lg: 10, offset: 17 }}},[_c('a-button',{staticStyle:{"margin-right":"8px"},attrs:{"type":"batal"},on:{"click":_vm.Batal}},[_vm._v(" Batal ")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v(" Reset ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }