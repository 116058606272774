var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":_vm.title,"visible":_vm.visible,"centered":true,"footer":null,"width":"600px"},on:{"cancel":_vm.handleCancel}},[(_vm.otp)?_c('div',{staticStyle:{"font-size":"16px","font-weight":"400"}},[_vm._v(" Masukkan OTP yang sudah di kirim ke Email Anda ")]):_vm._e(),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleOk}},[(_vm.otp)?_c('a-form-item',{attrs:{"label":"Kode OTP"}},[_c('div',{attrs:{"slot":"extra"},slot:"extra"},[(_vm.countDown > 0)?_c('span',[_vm._v("OTP akan berakhir dalam "+_vm._s(_vm.countDown)+" detik")]):_c('span',[_vm._v(" Resend OTP Click "),_c('a-button',{attrs:{"type":"link"},on:{"click":_vm.ResendOtp}},[_vm._v("Here")])],1)]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'otp',
          {
            rules: [
              {
                required: true,
                message: 'Field ini tidak boleh kosong',
              },
            ],
          },
        ]),expression:"[\n          'otp',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Field ini tidak boleh kosong',\n              },\n            ],\n          },\n        ]"}],attrs:{"autocomplete":"off","placeholder":"Masukan Kode"}})],1):_c('a-form-item',{attrs:{"label":"Email"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          {
            rules: [
              {
                required: true,
                message: 'Field ini tidak boleh kosong',
              },
            ],
          },
        ]),expression:"[\n          'email',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Field ini tidak boleh kosong',\n              },\n            ],\n          },\n        ]"}],attrs:{"autocomplete":"off","type":"email","placeholder":"Input email","disabled":_vm.disabled}})],1),_c('a-form-item',{attrs:{"wrapper-col":{ lg: 5, offset: 21 }}},[(_vm.otp)?_c('a-button',{attrs:{"type":"primary","loading":_vm.loading,"html-type":"submit"}},[_vm._v("Kirim")]):_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v("Kirim")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }