<template>
  <div>
    <a-modal
      title="Please Change your Password"
      v-model="modals"
      :centered="true"
      width="600px"
      :footer="null"
      :closable="false"
      :maskClosable="false"
      :keyboard="false"
    >
      <a-form :form="form" @submit="submit">
        <a-form-item label="Password">
          <a-input-password
            v-decorator="[
              'password',
              {
                rules: [
                  {
                    required: true,
                    message: 'Silahkan isi password baru!',
                  },
                  {
                    min: 6,
                    message: 'Karakter password harus lebih dari 6, ',
                  },
                  {
                    validator: RegexPassword,
                  },
                ],
              },
            ]"
            @blur="handleConfirmBlur"
            placeholder="Password Baru"
          />
        </a-form-item>

        <a-form-item label="Konfirmasi Password">
          <a-input-password
            v-decorator="[
              'confirm_password',
              {
                rules: [
                  {
                    required: true,
                    message: 'Silahkan konfirmasi password baru',
                  },
                  { validator: compareToFirstPassword },
                ],
              },
            ]"
            @blur="handleConfirmBlur"
            placeholder="Konfrimasi Password Baru"
          />
        </a-form-item>

        <a-form-item :wrapper-col="{ lg: 10, offset: 17 }">
          <a-button type="batal" @click="Batal" style="margin-right: 8px">
            Batal
          </a-button>
          <a-button type="primary" html-type="submit" :loading="loading">
            Reset
          </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "EditPassword" });
  },

  data() {
    return {
      modals: false,
      loading: false,
      id: "",
    };
  },
  methods: {
    Batal() {
      this.modals = false;
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },

    // sameToFirstPassword(rule, value, callback) {
    //   const form = this.form;
    //   if (value && value == form.getFieldValue("passwordlama")) {
    //     callback("password baru tidak bole sama dengan password lama!");
    //   } else {
    //     callback();
    //   }
    // },
    RegexPassword(rule, value, callback) {
      if (value && /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/.test(value)) {
        callback();
      } else {
        callback("Harus mengandung karakter dan angka");
      }
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("password")) {
        callback("Konfirmasi password tidak sesuai!");
      } else {
        callback();
      }
    },

    Show(id) {
      this.modals = true;
      this.id = id;
    },
    submit(e) {
      e.preventDefault();

      this.form.validateFields((err, value) => {
        if (!err) {
          this.loading = true;
          this.$store
            .dispatch("NewPassword", {
              id: this.id,
              ...value,
            })
            .then((response) => {
              this.loading = false;
              this.$notification.success({
                message: response.data.message,
              });

              this.modals = false;
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>
