<template>
  <div class="sub-box">
    <div v-if="isAuth">... Loading</div>
    <div v-else>
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item
          label="Email"
          :validate-status="userNameError() ? 'error' : ''"
          :help="userNameError() || ''"
        >
          <a-input
            autocomplete="off"
            v-decorator="[
              'email',
              {
                rules: [
                  { required: true, message: 'Field ini tidak boleh kosong!' },
                ],
              },
            ]"
            type="text"
            placeholder="Masukan alamat email"
          >
            <!-- <a-icon
            slot="prefix"
            type="user"
            style="color: rgba(0, 0, 0, 0.25)"
          /> -->
          </a-input>
        </a-form-item>
        <a-form-item
          label="Password"
          :validate-status="passwordError() ? 'error' : ''"
          :help="passwordError() || ''"
        >
          <a-input-password
            v-decorator="[
              'password',
              {
                rules: [
                  { required: true, message: 'Field ini tidak boleh kosong!' },
                ],
              },
            ]"
            type="password"
            placeholder="Masukan password unik anda"
          >
            <!-- <a-icon
            slot="prefix"
            type="lock"
            style="color: rgba(0, 0, 0, 0.25)"
          /> -->
          </a-input-password>
        </a-form-item>
        <a-form-item>
          <vue-recaptcha
            v-if="url == 'https:'"
            sitekey="6LeOZcEnAAAAALKgD1IsIpSGEU76tU7RZjJUvjSQ"
            @verify="enableBtn"
            :loadRecaptchaScript="true"
          ></vue-recaptcha>
        </a-form-item>
        <a-form-item>
          <div
            style="display: flex; justify-content: space-between; width: 100%"
          >
            <a-button type="link" @click="ForgotPassword"
              >Lupa Password</a-button
            >
            <a-button
              type="primary"
              html-type="submit"
              class="login-form-button"
              :loading="loading"
              :disabled="
                url == 'https:' ? disabled : hasErrors(form.getFieldsError())
              "
            >
              Masuk
            </a-button>
          </div>
        </a-form-item>
      </a-form>
      <ForgotPassword ref="Forgot" @reset="NewPwd" />
      <NewPassword ref="Password" />
    </div>
  </div>
</template>
<script>
function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}
import { mapState, mapGetters } from "vuex";
import VueRecaptcha from "vue-recaptcha";

import ForgotPassword from "@/components/Modal/ForgotPassword.vue";
import NewPassword from "@/components/Modal/NewPassword.vue";
export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  components: {
    ForgotPassword,
    NewPassword,
    VueRecaptcha,
  },
  data() {
    return {
      hasErrors,
      loading: false,
      url: window.location.protocol,
      disabled: true,
    };
  },
  name: "auth",
  computed: {
    ...mapState(["token"]),
    ...mapGetters(["isAuth"]),
  },
  created() {
    if (this.isAuth == true) {
      this.$router.push({ name: "Home" });
    }
  },
  mounted() {
    this.disabled = this.url == "https:" ? true : false;
    this.$nextTick(() => {
      // To disabled submit button at the beginning.
      this.form.validateFields();
    });
  },

  methods: {
    enableBtn() {
      this.disabled = false;
    },
    NewPwd(item) {
      console.log(item);
      this.$refs.Password.Show(item);
    },
    userNameError() {
      const { getFieldError, isFieldTouched } = this.form;

      return isFieldTouched("email") && getFieldError("email");
    },
    // Only show error after a field is touched.
    passwordError() {
      const { getFieldError, isFieldTouched } = this.form;

      return isFieldTouched("password") && getFieldError("password");
    },

    ForgotPassword() {
      const email = this.form.getFieldValue("email");
      this.$refs.Forgot.Show(email);
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // console.log(values);
          this.loading = true;
          this.$store
            .dispatch("retrieveToken", {
              email: values.email.toLowerCase(),
              password: values.password,
            })
            .then((response) => {
              this.loading = false;
              if (this.isAuth) {
                this.$router.push({ name: "Home" }, () => {});
              }
            })
            .catch((error) => {
              this.loading = false;
            });
          // this.$router.push({ name: "Home" }, () => {});
        }
      });
    },
  },
};
</script>
